import React from "react";
import Slider from "react-slick";

import "./_sampleSlider.scss";

var settings = {
  centerMode: false,
  infinite: false,
  slidesToShow: 4,
  dots: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 2000,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 820,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

const ListSlider = ({ handleUpdate }) => {
  const updateProject = (e, projectName) => {
    handleUpdate(projectName);
  };

  return (
    <>
      <Slider {...settings} className="sample-list-slider">
        <div className="slider-item">
          <div
            className="list-item list-item-dark"
            onClick={(e) => updateProject(e, "hiporecruiting")}
            onKeyDown={(e) => updateProject(e, "hiporecruiting")}
            role="button"
            tabIndex="0"
          >
            <p className="project-name" id="hiporecruiting">
              HiPo Recruiting
            </p>
          </div>
          <div
            className="list-item list-item-light"
            onClick={(e) => updateProject(e, "bcli")}
            onKeyDown={(e) => updateProject(e, "bcli")}
            role="button"
            tabIndex="0"
          >
            <p className="project-name" id="bcli">
              BC Law Institute
            </p>
          </div>
        </div>
        <div className="slider-item">
          <div
            className="list-item list-item-light"
            onClick={(e) => updateProject(e, "theacquisitiongroup")}
            onKeyDown={(e) => updateProject(e, "theacquisitiongroup")}
            role="button"
            tabIndex="0"
          >
            <p className="project-name" id="theacquisitiongroup">
              The Acquisition Group
            </p>
          </div>
          <div
            className="list-item list-item-dark"
            onClick={(e) => updateProject(e, "firefly")}
            onKeyDown={(e) => updateProject(e, "firefly")}
            role="button"
            tabIndex="0"
          >
            <p className="project-name" id="firefly">
              Firefly
            </p>
          </div>
        </div>
        <div className="slider-item">
          <div
            className="list-item list-item-dark"
            onClick={(e) => updateProject(e, "eric")}
            onKeyDown={(e) => updateProject(e, "eric")}
            role="button"
            tabIndex="0"
          >
            <p className="project-name" id="eric">
              Eric Termuende
            </p>
          </div>
          <div
            className="list-item list-item-light"
            onClick={(e) => updateProject(e, "pocketed")}
            onKeyDown={(e) => updateProject(e, "pocketed")}
            role="button"
            tabIndex="0"
          >
            <p className="project-name" id="pocketed">
              Pocketed
            </p>
          </div>
        </div>
        <div className="slider-item">
          <div
            className="list-item list-item-light"
            onClick={(e) => updateProject(e, "nobelfig")}
            onKeyDown={(e) => updateProject(e, "nobelfig")}
            role="button"
            tabIndex="0"
          >
            <p className="project-name" id="nobelfig">
              Noble Fig
            </p>
          </div>
          <div
            className="list-item list-item-dark"
            onClick={(e) => updateProject(e, "unifiedengineering")}
            onKeyDown={(e) => updateProject(e, "unifiedengineering")}
            role="button"
            tabIndex="0"
          >
            <p className="project-name" id="unifiedengineering">
              Unified Engineering
            </p>
          </div>
        </div>
      </Slider>
      <button
        className="see-all-btn hidden project-name"
        id="see-all-btn"
        onClick={(e) => updateProject(e, "see-all-btn")}
        onKeyDown={(e) => updateProject(e, "see-all-btn")}
      >
        See All Examples
      </button>
    </>
  );
};

export default ListSlider;
