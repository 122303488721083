import React from "react";
import Slider from "react-slick";
import "../_sampleSlider.scss";

import DesktopBCLI from "../../../images/TSDprojects/TSD_BCLI_Desktop.jpg";
import MobileBCLI from "../../../images/TSDprojects/TSD_BCLI_Mobile.jpg";
import MobileHipo from "../../../images/TSDprojects/TSD_Hipo_Mobile.jpg";
import DesktopHipo from "../../../images/TSDprojects/TSD_Hipo_Desktop.jpg";
import TabletElminda from "../../../images/TSDprojects/TSD_Firefly_Tablet.png";
import TabletNoble_Fig from "../../../images/TSDprojects/TSD_Noble_Fig_Tablet.jpg";
import TabletPocketed from "../../../images/TSDprojects/TSD_Pocketed_Tablet.jpg";
import DesktopPocketed from "../../../images/TSDprojects/TSD_Pocketed_Desktop.jpg";
import MobileTAG from "../../../images/TSDprojects/TSD_TAG_Mobile.jpg";
import TabletTAG from "../../../images/TSDprojects/TSD_TAG_Tablet.jpg";
import TabletHipo from "../../../images/TSDprojects/TSD_Hipo_Tablet.jpg";
import DesktopUnified from "../../../images/TSDprojects/TSD_Unified_Desktop.jpg";
import TabletUnified from "../../../images/TSDprojects/TSD_Unified_Tablet.jpg";
import MobileEric from "../../../images/TSDprojects/TSD_Eric_Mobile.jpg";
import DesktopEric from "../../../images/TSDprojects/TSD_Eric_Desktop.jpg";
var settings = {
  centerMode: false,
  infinite: true,
  slidesToShow: 4,
  dots: false,
  arrows: true,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2500,
  responsive: [
    {
      breakpoint: 2000,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 820,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const AllSampleWorks = ({ handleUpdate }) => {
  const updateProject = (projectName) => {
    handleUpdate(projectName);
  };
  return (
    <>
      <div className="container narrow-container individual-project-wrapper">
        <div className="content-wrapper">
          <h2>Sampling of Our Creations</h2>
          <p>
            Now, do not expect the same results from this small collection of
            online creations of ours. There is so much that goes into the final
            result that depends on your brand, visual language, content and of
            course – your budget. So what we have tried to showcase is a full
            spectrum of results, based on all of those factors.
          </p>
        </div>
      </div>
      <Slider {...settings} className="individual-work-slider  all-work-sample">
        <div className="slider-item item-desktop">
          <input
            type="image"
            src={DesktopUnified}
            alt=""
            onClick={() => updateProject("unifiedengineering")}
          />
        </div>
        <div className="slider-item item-mobile double-image">
          <input
            type="image"
            src={MobileTAG}
            alt=""
            onClick={() => updateProject("theacquisitiongroup")}
          />
          <input
            type="image"
            src={MobileEric}
            alt=""
            onClick={() => updateProject("eric")}
          />
        </div>
        <div className="slider-item item-desktop">
          <input
            type="image"
            src={DesktopEric}
            alt=""
            onClick={() => updateProject("eric")}
          />
        </div>
        <div className="slider-item item-tablet double-image">
          <input
            type="image"
            src={TabletHipo}
            alt=""
            onClick={() => updateProject("hiporecruiting")}
          />
          <input
            type="image"
            src={TabletTAG}
            alt=""
            onClick={() => updateProject("theacquisitiongroup")}
          />
        </div>
        <div className="slider-item item-desktop">
          <input
            type="image"
            src={DesktopBCLI}
            alt=""
            onClick={() => updateProject("bcli")}
          />
        </div>
        <div className="slider-item item-tablet double-image">
          <input
            type="image"
            src={TabletPocketed}
            alt=""
            onClick={() => updateProject("pocketed")}
          />
          <input
            type="image"
            src={TabletElminda}
            alt=""
            onClick={() => updateProject("firefly")}
          />
        </div>
        <div className="slider-item item-desktop">
          <input
            type="image"
            src={DesktopPocketed}
            alt=""
            onClick={() => updateProject("pocketed")}
          />
        </div>
        <div className="slider-item item-mobile double-image">
          <input
            type="image"
            src={MobileHipo}
            alt=""
            onClick={() => updateProject("hiporecruiting")}
          />
          <input
            type="image"
            src={MobileBCLI}
            alt=""
            onClick={() => updateProject("bcli")}
          />
        </div>

        <div className="slider-item item-desktop">
          <input
            type="image"
            src={DesktopHipo}
            alt=""
            onClick={() => updateProject("hiporecruiting")}
          />
        </div>
        <div className="slider-item item-tablet double-image">
          <input
            type="image"
            src={TabletNoble_Fig}
            alt=""
            onClick={() => updateProject("nobelfig")}
          />
          <input
            type="image"
            src={TabletUnified}
            alt=""
            onClick={() => updateProject("unifiedengineering")}
          />
        </div>
      </Slider>
    </>
  );
};
export default AllSampleWorks;
