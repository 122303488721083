import React from "react";
import { Link } from "gatsby";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Layout from "~components/base/layout";
import SEO from "~components/base/seo";
import SampleWorks from "~components/sample-work/sampleWorks";
import ProductsSlider from "~components/products/productsSlider";
import "../styles/pages/_lp.scss";
import webBuildMachine from "../images/lp/TSD_Complicated_Hero.png";

var settings = {
  centerMode: true,
  infinite: true,
  centerPadding: "700px",
  slidesToShow: 1,
  dots: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 2500,
      settings: {
        centerPadding: "500px",
      },
    },
    {
      breakpoint: 2000,
      settings: {
        centerPadding: "300px",
      },
    },
    {
      breakpoint: 1400,
      settings: {
        centerPadding: "100px",
      },
    },
    {
      breakpoint: 821,
      settings: {
        centerPadding: "40px",
      },
    },
    {
      breakpoint: 600,
      settings: {
        centerPadding: "0px",
      },
    },
  ],
};

const SimpleEffectiveWebsites = () => (
  <Layout type="marketin-stuck-lp template-lp">
    <SEO
      title="Simple, Effective Websites"
      description="Need a simple but effective website that works on every screen size, loads quickly and coverts? Welcome to The Simple Department."
    />

    <section className="da-lp-hero website-lp-hero">
      <img src={webBuildMachine} />
      <div className="container">
        <div className="content-wrapper">
          <h2>Make the Web</h2>
          <h1>Work for You.</h1>
          <p>
            Not everyone needs a complex, expensive website that takes months to
            build. Sometimes you need something straightforward, affordable and
            effective. Our Off-The-Shelf Web packages are right-sized to suit
            your budget from small to medium to Shopify (but like, yuck).
          </p>
          <p className="btn-cta-wrapper">
            <strong>
              <Link to="#TSD-Website_Packages" className="lp-btn red-btn">
                Is your Internet Home Ready for Guests to Arrive?
              </Link>
            </strong>
          </p>
        </div>
      </div>
    </section>
    <section className="ms-lp-slider">
      <Slider {...settings} className="symptoms-slider">
        <div className="give-padding">
          <div className="single-slide website-slide1">
            <div className="slide-content">
              <h3>Avoid Tech Debt</h3>
              <p>
                The more complicated you make your website, the more work is
                going to be involved in managing it, keeping it optimized and
                avoiding it becoming something you want to throw out and start
                over with. Once you have this sales machine working hard for
                you, then invest more into it, because you know it is going to
                pay for itself.
              </p>
            </div>
          </div>
        </div>
        <div className="give-padding">
          <div className="single-slide website-slide2">
            <div className="slide-content">
              <h3>Shaky Foundations</h3>
              <p>
                The most frustrating thing you can do with a website project is
                pick technology that is going to force you to completely
                re-build in 6 months, rather than something you can continually
                add to and make better. Make the right decisions early and
                scaling becomes super straightforward.
              </p>
            </div>
          </div>
        </div>
        <div className="give-padding">
          <div className="single-slide website-slide3">
            <div className="slide-content">
              <h3>Do You Really Need That? For Sure?</h3>
              <p>
                Don’t overcomplicate your needs. Think you need to be leveraging
                Web3 and jumping into the metaverse? If you landed here from one
                of our ads, I can assure you, you are not ready for the
                meta-verse and you don’t need to be carrying all of that
                tech-debt. Keep it simple, just get it live and working.
              </p>
            </div>
          </div>
        </div>
      </Slider>
      <div className="container narrow-container">
        <h2 className="text-center">Just like a Real World Home…</h2>
        <p className="text-center spacer-1">
          It’s all about how you furnish it with content and maintain both the
          hidden and visible structure. You need to keep refreshing each area to
          keep it inviting, purposeful, and enjoyable for your guests whenever
          they visit.
        </p>
        <p className="btn-cta-wrapper text-center">
          <strong>
            <Link to="#TSD-Website_Packages" className="lp-btn white-btn">
              Ready to be Meta?
            </Link>
          </strong>
        </p>
      </div>
    </section>
    <section className="website-lp-work-samples" id="website-lp-work-samples">
      <SampleWorks />
    </section>
    <section className="website-lp-shout">
      <div className="container">
        <div className="content-wrapper">
          <h2>You Can’t be an Option if People Can’t Find You…</h2>
          <p>
            <strong>
              Every minute you are not online is an opportunity lost.
            </strong>
            <br></br>That is the beauty of the web – the continual growth,
            improvement, iterations and adjustments to keep making your branded
            web experience as amazing and fresh as possible. But we all need to
            start somewhere and the sooner you are alive, the better.
          </p>
          <p className="btn-cta-wrapper">
            <strong>
              <Link to="#TSD-Website_Packages" className="lp-btn blue-btn">
                Can we break out our tool belts?
              </Link>
            </strong>
          </p>
        </div>
      </div>
    </section>

    <section className="ms-lp-products" id="TSD-Website_Packages">
      <div className="container">
        <div className="content-wrapper">
          <h2 className="text-center">Starting Small is Always Smart</h2>
        </div>
        <ProductsSlider>
          <div title="Web Kit 1.0" price="$5,525" id="website-opt1-basic">
            <p>
              No commerce, no complex integration. You provide us with the
              complete site copy, content, images & brand assets, and we design
              and build you a basic 6-8 page site, including a blog template and
              sitemap.
            </p>
          </div>
          <div title="Web Kit 1.1" price="$7,650" id="website-opt1-pluscontent">
            <p>
              No commerce, no complex integration. The Web Kit 1.0 package, but
              our talented copywriting team will also handle the complete,
              SEO-friendly copy for your entire 6-8 page site at the beginning
              of the process.
            </p>
          </div>
          <div title="Web Kit 2.0" price="$12,750" id="website-opt2-basic">
            <p>
              Simple commerce. You provide us with the complete site copy,
              content, images & brand assets and we design and build. We create
              all the pages needed and handle the setup of shipping options,
              tax, and product templates. You add all your products, including
              images & descriptions.
            </p>
          </div>
          <div title="Web Kit 2.1" price="$17,000" id="website-opt2-complete">
            <p>
              The Web Kit 2.0 package, but our copywriting team will handle the
              SEO-friendly copy for your entire site, including 15 product
              descriptions and add all your products. You provide us with the
              info, images, product & price list and we’ll give you back and
              complete ecommerce site.
            </p>
          </div>
          <div title="Web Kit 3.0" price="$30,000" id="website-opt3-basic">
            <p>
              Working with a baseline of something like Shopify, WooCommerce,
              BigCommerce, Magento, Commerce Cloud, HubSpot CMS, or REACT, we
              plan and build a web platform that is designed to maximize your
              brand’s online experience and integrate with robust marketing
              software, flows and sales processes to drive revenue, either
              directly on the website or through highly qualified sales leads.
            </p>
          </div>
          <div title="Web Kit 3.1" price="$40,000" id="website-opt3-complete">
            <p>
              Web Platforms of this scale require a very clear content map and a
              really strong plan for how content will continue to be developed,
              published and distributed so that you can maximize your new
              investment and strengthen your online visibility, brand attraction
              and positioning - and of course conversion to revenue. Most brands
              and businesses we work with need this part of the project almost
              more than they need an actual website.
            </p>
          </div>
        </ProductsSlider>
      </div>
    </section>
  </Layout>
);

export default SimpleEffectiveWebsites;
