import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import { Link } from "gatsby";
import "./_products.scss";

const Desc = ({ children }) => {
  const [height, setHeight] = useState(0);
  const [visible, setVisible] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    setHeight(elementRef.current.clientHeight);
  }, []);

  const onClick = () => {
    setVisible(!visible);
  };

  return (
    <div className="product-desc" ref={elementRef}>
      {height > 250 && window.innerWidth < 1700 ? (
        <>
          <div className={visible ? "" : "product-desc-limit"}>{children}</div>
          <button onClick={onClick} className="btn-toggle">
            {visible ? "Show less" : "Show more"}
          </button>
        </>
      ) : (
        <>{children}</>
      )}
    </div>
  );
};
var settings = {
  centerMode: false,
  infinite: false,
  slidesToShow: 3,
  dots: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 1700,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 821,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
const ProductsSlider = ({ children, type }) => {
  const childes = React.Children.toArray(children);
  return (
    <Slider {...settings} className="slider-products">
      {childes.map((child, index) => (
        <div className="slider-product-item" key={index}>
          <div className="inner-slider">
            <div>
              <h3>{child.props.title}</h3>
              <h3>{child.props.duration}</h3>
              <p>{child.props.children}</p>
            </div>

            <div>
              <h1>{child.props.price}</h1>

              {child.props.extrainfo ? (
                <p className="extrainfo-text">{child.props.extrainfo}</p>
              ) : null}
              {child.props.extrainfo2 ? (
                <p className="extrainfo-text">{child.props.extrainfo2}</p>
              ) : null}

              <p className="btn-wrapper">
                <Link
                  to="/checkout"
                  className="btn blue-btn round-corner"
                  state={{
                    id: child.props.id,
                  }}
                >
                  LEARN MORE
                </Link>
              </p>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default ProductsSlider;
