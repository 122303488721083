import React from "react";
import Slider from "react-slick";
import "../_sampleSlider.scss";
import Desktop from "../../../images/TSDprojects/TSD_Elminda_Desktop.jpg";

import Tablet from "../../../images/TSDprojects/TSD_Firefly_Tablet.png";
import Mobile from "../../../images/TSDprojects/TSD_Firefly_Mobile.png";

var settings = {
  centerMode: false,
  infinite: false,
  slidesToShow: 4,
  dots: false,
  arrows: true,
  responsive: [
    {
      breakpoint: 2000,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 820,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
const Firefly = () => {
  return (
    <>
      <div className="container narrow-container individual-project-wrapper">
        <div className="content-wrapper">
          <p className="title">OUR PROJECTS</p>
          <h2>Firefly Neuroscience</h2>
          <p>
            A custom-built Wordpress site, this project needed to tackle a
            complex, cutting-edge subject, while making the information easy to
            navigate and digest for an investor-based audience. This project
            included interactive infographic elements and a unique, carefully
            crafted visual language.
          </p>
        </div>
      </div>
      <Slider {...settings} className="individual-work-slider">
        <div className="slider-item item-desktop">
          <input type="image" src={Desktop} alt="" />
        </div>
        <div className="slider-item item-tablet">
          <input type="image" src={Tablet} alt="" />
        </div>
        <div className="slider-item item-mobile">
          <input type="image" src={Mobile} alt="" />
        </div>
      </Slider>
    </>
  );
};
export default Firefly;
