import React, { useState } from "react";
import ListSlider from "./listSlider";

import AllSampleWorks from "./individual-projects/allWorkSamples";
import BCLI from "./individual-projects/bcli";
import Firefly from "./individual-projects/elminda";
import HiPoRecruiting from "./individual-projects/hiporecruiting";
import UnifiedEngineering from "./individual-projects/unifiedengineering";
import NobleFig from "./individual-projects/nobelfig";
import Pocketed from "./individual-projects/pocketed";
import TheAcquisitionGroup from "./individual-projects/theacquisitiongroup";
import Eric from "./individual-projects/eric";

import "./_sampleSlider.scss";

const SampleWorks = () => {
  const [currentProject, setCurrentProject] = useState("see-all-btn");

  const updateProject = (projectName) => {
    const projectSection = document.getElementById("website-lp-work-samples");
    projectSection.scrollIntoView();

    setCurrentProject(projectName);
    // Display and hide See All Examples btn
    const seeAllBtn = document.querySelector("#see-all-btn");
    projectName !== "see-all-btn"
      ? seeAllBtn.classList.remove("hidden")
      : seeAllBtn.classList.add("hidden");

    //Add underline to the current project name
    const allProjects = document.querySelectorAll(".project-name");
    allProjects.forEach((projectName) => {
      projectName.classList.remove("underline");
    });
    const activeProject = document.querySelector(`#${projectName}`);
    if (activeProject !== "see-all-btn") {
      activeProject.classList.add("underline");
    }
    displayProject();
  };
  const displayProject = () => {
    switch (currentProject) {
      case "eric":
        return <Eric />;
      case "bcli":
        return <BCLI />;
      case "firefly":
        return <Firefly />;
      case "hiporecruiting":
        return <HiPoRecruiting />;
      case "unifiedengineering":
        return <UnifiedEngineering />;
      case "nobelfig":
        return <NobleFig />;
      case "pocketed":
        return <Pocketed />;
      case "theacquisitiongroup":
        return <TheAcquisitionGroup />;
      case "see-all-btn":
        return <AllSampleWorks handleUpdate={updateProject} />;
      default:
        return <AllSampleWorks handleUpdate={updateProject} />;
    }
  };
  return (
    <div className="sample-work-wrapper">
      {displayProject()}
      <ListSlider handleUpdate={updateProject} />
    </div>
  );
};
export default SampleWorks;
