import React from "react";
import Slider from "react-slick";
import "../_sampleSlider.scss";
import Desktop from "../../../images/TSDprojects/TSD_Pocketed_Desktop.jpg";
import Tablet from "../../../images/TSDprojects/TSD_Pocketed_Tablet.jpg";
import Mobile from "../../../images/TSDprojects/TSD_Pocketed_Mobile.jpg";

var settings = {
  centerMode: false,
  infinite: false,
  slidesToShow: 4,
  dots: false,
  arrows: true,
  responsive: [
    {
      breakpoint: 2000,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 820,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
const Pocketed = () => {
  return (
    <>
      <div className="container narrow-container individual-project-wrapper">
        <div className="content-wrapper">
          <p className="title">OUR PROJECTS</p>
          <h2>Pocketed</h2>
          <p>
            Re-inventing how businesses and founders learn about and access
            grant funding means creating an experience from the ground up to
            help visitors navigate through the education journey and into
            becoming subscribers
          </p>
        </div>
      </div>
      <Slider {...settings} className="individual-work-slider">
        <div className="slider-item item-desktop">
          <input type="image" src={Desktop} alt="" />
        </div>
        <div className="slider-item item-tablet">
          <input type="image" src={Tablet} alt="" />
        </div>
        <div className="slider-item item-mobile">
          <input type="image" src={Mobile} alt="" />
        </div>
      </Slider>
    </>
  );
};
export default Pocketed;
